import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from '../store';
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import DocumentView from "../views/DocumentView.vue";
import FAQsView from "../views/FAQsView.vue";
import ContactUsView from "../views/ContactUsView.vue";
import SubmissionView from "../views/developers-view/SubmissionView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ProjectDetailView from "@/views/developers-view/ProjectDetailView.vue";
import VersionView from "@/views/developers-view/VersionView.vue";
import AboutView from "@/views/AboutView.vue";
import DeveloperRouterView from "@/views/developers-view/DeveloperRouterView.vue";
import ManageTesterView from "@/views/developers-view/ManageTesterView.vue";
import MyProjectRouterView from "@/views/developers-view/MyProjectRouter.vue";
import TestStagingView from "@/views/developers-view/project-detail/TestStagingView.vue";

import ProfileView from "@/views/ProfileView.vue";
import BusinessPartnerView from "@/views/BusinessPartnerView.vue";
import StartSubmissionView from "@/views/StartSubmissionView.vue";
import ThreeDSubmissionView from "@/views/developers-view/3DSubmissionView.vue";
import StripeView from "@/views/stripe-view/StripeView.vue";
import StripeConnectedView from "@/views/stripe-view/StripeConnectedView.vue";
import CreatorView from "@/views/CreatorView.vue";
import CommissionView from "@/views/CommissionView.vue";
import VPSPlacementView from "@/views/vps-view/VPSPlacementView.vue";
import ConfigPlacementView from "@/views/vps-view/ConfigPlacementView.vue";
import PurchaseHistoryView from "@/views/PurchaseHistoryView.vue";
import VPSView from "@/views/vps-view/VPSView.vue";
import { ElMessage } from "element-plus";

function withoutToken(to) {
  return to.meta.requiresAuth && !localStorage.getItem("platform_accesstoken");
}
function onlyAbroad(to) {
  return to.meta.onlyAbroad && process.env.NODE_ENV === "cn-development";
}
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
  },
  {
    path: "/start-submission",
    name: "Start Submission",
    component: StartSubmissionView,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => checkStripe(next)
  },

  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/document",
    name: "Document",
    component: DocumentView,
  },
  {
    path: "/business",
    name: "Business Partners",
    component: BusinessPartnerView,
  },

  {
    path: "/developers",
    name: "Developers",
    component: DeveloperRouterView,
    children: [
      {
        path: "my-project",
        name: "My Projects",
        // component: MyProjectsView,
        component: MyProjectRouterView,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "3D-model-submission",
            name: "Submission of 3D Models",
            component: ThreeDSubmissionView,
            beforeEnter: (to, from, next) => checkStripe(next)
          },
          {
            path: "submission",
            name: "Game Submission",
            component: SubmissionView,
            beforeEnter: (to, from, next) => checkStripe(next)
          },
          {
            path: "detail",
            name: "Detail",
            component: ProjectDetailView,
          },
          {
            path: "version",
            name: "Version",
            component: VersionView,
            beforeEnter: (to, from, next) => checkStripe(next)
          },
          {
            path: "tester",
            name: "Manage Tester",
            component: ManageTesterView,
          },
          {
            path: "test-staging",
            name: "Test Staging Site",
            component: TestStagingView,
          },
        ],
      },
    ],
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FAQsView,
  },
  {
    path: "/profile",
    name: "Edit Profile",
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/contact",
    name: "Contact Us",
    component: ContactUsView,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordView,
  },
  {
    path: "/stripe",
    name: "Connect your Stripe Account",
    component: StripeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/stripe-connected",
    name: "Stripe Connected",
    component: StripeConnectedView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/creator",
    name: "Creator's World",
    component: CreatorView,
  },
  {
    path: "/commission",
    name: "My Project Income",
    component: CommissionView,
  },
  //   {
  //     path: "/vps-placement",
  //     name: "My VPS Placement",
  //     component: VPSPlacementView,
  //     meta: {
  //       requiresAuth: true,
  //       onlyAbroad: true,
  //     },
  //   },
  //   {
  //     path: "/config-vps-placement",
  //     name: "Config VPS AR Placement",
  //     component: ConfigPlacementView,
  //     meta: {
  //       requiresAuth: true,
  //       onlyAbroad: true,
  //     },
  //   },
  {
    path: "/purchase-history",
    name: "My Purchase History",
    component: PurchaseHistoryView,
    meta: {
      requiresAuth: true,
      onlyAbroad: true,
    },
  },
  //   {
  //     path: "/vps",
  //     name: "Visual Positioning System (VPS)",
  //     component: VPSView,
  //     meta: {
  //       requiresAuth: true,
  //       onlyAbroad: true,
  //     },
  //   },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 递归函数，用于获取所有路径，包括多级子路由
function getAllPaths(routes, basePath = '') {
  const paths = new Set();
  routes.forEach(route => {
    const fullPath = basePath + route.path;
    paths.add(fullPath);
    if (route.children) {
      const childPaths = getAllPaths(route.children, fullPath + '/');
      childPaths.forEach(path => paths.add(path));
    }
  });
  return paths;
}

const allPaths = getAllPaths(routes);
router.beforeEach((to, from, next) => {
  if (!allPaths.has(to.path)) {
    next({ name: "Home" }); // Redirect to home if path is not defined
  } else if (to.name !== "Login" && withoutToken(to)) {
    next({ name: "Login" });
  } else if (onlyAbroad(to)) {
    next({ name: "Home" });
  } else {
    next();
  }
});

function checkStripe(next) {
  store.dispatch("getMyProfile").then(response => {
    if (response.connectedToStripe) {
      next()
    } else {
      next({ path: '/stripe' })
      ElMessage.warning("Please connect the stripe account first.");
    }
  }, error => {
    next({ path: '/stripe' })
    ElMessage.warning("Please connect the stripe account first.");
  })
}

export default router;
